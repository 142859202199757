import Promise from 'bluebird';
import { types, flow, getSnapshot, onPatch } from 'mobx-state-tree';
import moment from 'moment';

const Message = types
    .model('Message', {
        from: types.string,
        sent_at: types.number,
        message: types.string
    })
    .views(self => ({
        get time () {
            return moment(self.sent_at).format('HH:mm');
        }
    }))

const instantChat = new URL(document.location.href).searchParams.has('instant');

const Store = types
    .model('Store', {
        isWaitingForChat: !instantChat,
        isTyping: false,
        isTextFieldFocused: false,
        name: types.maybeNull(types.string),
        conversation: types.array(Message),
        isConversationOver: false,
        isShowingNamePrompt: false,
        isOptedIn: false,
        isAssignedAgent: false,
        pendingMessage: types.maybeNull(types.string),

        senderCountry: types.maybeNull(types.string),
        senderAge: types.maybeNull(types.string),
        senderEmail: types.maybeNull(types.string)
    })

    .views(self => ({
        get hasEmail () {
            return !!(
                self.conversation
                    .filter(message => message.from == 'receiver')
                    .find(message => message.message.match(/Please continue communicating over EMAIL, PHONE, or LINE/))
            );
        }
    }))

    .actions(self => {
        const setName = flow(function * setName(name) {
            self.name = name;

            // track first message
            window.gtag_report_first_message();

            self.isShowingNamePrompt = false;

            let pendingMessage = self.pendingMessage;
            self.pendingMessage = null;
            yield sendMessage(pendingMessage);
        });

        const sendMessage = flow(function * sendMessage(message) {
            self.isTyping = true;

            self.conversation.push({
                from: 'sender',
                sent_at: new Date().getTime(),
                message
            });

            let response = yield fetch(
                'https://facebook.thaivisacentre.com/responder', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        conversation: {
                            from: {
                                name: self.name
                            },
                            messages: self.conversation.filter(message => message.from == 'sender').map(item => item.toJSON()),
                            pageMessages: self.conversation.filter(message => message.from == 'receiver').map(item => item.toJSON())
                        }
                    })
                }
            );

            response = yield response.json();

            if (response.message) {
                let messages = Array.isArray(response.message) ? response.message : [response.message];

                for (let message of messages) {
                    let delay = message.length * 75;
                    if (delay > 5000) {
                        delay = 5000;
                    }

                    // NOTE: WE ARE NOT ON FACEBOOK
                    message = message.replace('LINE.\n\nWe only use Facebook to build the client profile so we can hand you off to our team via the above forms of communication.', 'LINE.');

                    yield Promise.delay(delay);

                    self.conversation.push({
                        from: 'receiver',
                        sent_at: new Date().getTime(),
                        message
                    });
                }
            } else {
                self.isConversationOver = true;
                window.gtag_report_conversion();
            }

            let context = response.payload.context;

            if (context) {
                if (!self.senderAge && context.age) {
                    self.senderAge = String(context.age);
                }

                if (!self.senderCountry && context.country) {
                    self.senderCountry = context.country;
                }

                if (!self.senderEmail && context.email && context.email.length) {
                    self.senderEmail = context.email[context.email.length - 1];

                    if (window.fbq) {
                        window.fbq('track', 'CompleteRegistration', {
                            content_name: '1 Year Retirement Visa',
                            status: true,
                            currency: 'USD',
                            value: 1
                        });
                        window.uetq.push('event', 'lead', {'event_category': 'contact', 'event_label': 'conversion', 'event_value': '1'});
                    }
                }
            }

            self.isTyping = false;
        });

        const assignAgent = flow(function * assignAgent() {
            if (self.isAssignedAgent) {
                return;
            }

            self.isWaitingForChat = false;

            self.isTyping = true;
            yield Promise.delay(3000);
            self.isTyping = false;

            self.conversation.push({
                from: 'receiver',
                sent_at: new Date().getTime(),
                message: `What country are you from?`
            });
            self.isAssignedAgent = true;
        });

        const promptForName = pendingMessage => {
            self.isShowingNamePrompt = true;

            self.pendingMessage = pendingMessage;
        };

        const optIn = () => {
            self.isOptedIn = true;
        };

        const optOut = () => {
            self.isOptedIn = false;
        };

        return { setName, sendMessage, assignAgent, promptForName, optIn, optOut };
    });

let store = Store.create(
    localStorage.getItem('mobx-store') ? JSON.parse(localStorage.getItem('mobx-store')) : {}
);

onPatch(store, () => {
    localStorage.setItem('mobx-store', JSON.stringify(getSnapshot(store)));
});

export default store;