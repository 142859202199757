import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { observer } from 'mobx-react';
import Store from './models/Store';

import CTA from './CTA';
import Landing from './Landing';
import LineInfo from './LineInfo';
import NamePrompt from './NamePrompt';
import Chat from './Chat';
import ChatQueue from './ChatQueue';

import styles from './App.css';

const App = () => {
	const { name, senderCountry, senderAge, senderEmail, isWaitingForChat, isShowingNamePrompt, isOptedIn, assignAgent, conversation } = Store;

	const instantChat = new URL(document.location.href).searchParams.has('instant');
	const showCTA = new URL(document.location.href).searchParams.has('cta') && !isOptedIn && !instantChat;
	const showLanding = !showCTA && !isOptedIn && !instantChat;
	const [clicks, setClicks] = useState(0);
	const [showLineInfo, setShowLineInfo] = useState(false);

	useEffect(() => {
		let userVars = {
			displayName: name
		};

		if (senderCountry) {
			userVars.country_str = senderCountry;
		}

		if (senderAge) {
			userVars.age_str = senderAge;
		}

		if (senderEmail && senderEmail) {
			userVars.email = senderEmail;
		}

		window.FS.setUserVars(userVars);
	}, [ name, senderCountry, senderAge, senderEmail ]);

	useEffect(() => {
		if (instantChat) {
			assignAgent();
			if (window.fbq) {
				window.fbq('track', 'Contact');
			}
		}
	}, []);

	function renderNamePrompt () {
		if (name || isWaitingForChat || !isShowingNamePrompt || showCTA || showLanding) {
			return null;
		}

		return <NamePrompt />;
	}

	function renderChat () {
		if (isWaitingForChat || showCTA || showLanding) {
			return null;
		}

		return <Chat />;
	}

	function renderChatQueue () {
		if (!isWaitingForChat || showCTA || showLanding) {
			return null;
		}

		return <ChatQueue />;
	}

	function renderCTA () {
		if (!showCTA) {
			return null;
		}

		return <CTA />;
	}

	function renderLanding () {
		if (!showLanding) {
			return null;
		}

		return <Landing />;
	}

	function renderLine () {
		if (!showLineInfo) {
			return null;
		}

		return <LineInfo />;
	}

	function incrimentClicks() {
		if (clicks + 1 > 4 && conversation.length <= 1 && !isShowingNamePrompt) {
			setShowLineInfo(true);
		}

		setClicks(clicks + 1);
	}

	return <div className={ isMobile ? styles.mobileContainer : styles.desktopContainer} onClick={incrimentClicks}>
		{renderLine()}
		{renderCTA()}
		{renderLanding()}
		{renderChat()}
		{renderNamePrompt()}
		{renderChatQueue()}
	</div>;
};

module.exports = observer(App);