import React from 'react';
import { observer } from 'mobx-react';
import Store from './models/Store';
import styles from './CTA.css';

const CTA = () => {
	const { optIn } = Store;

	function onClick () {
		optIn();
	}

	return <div className={styles.container}>

			<div className={styles.content}>
				<div className={styles.header}>Thai Visa Centre<br /><span>Retirement Visa Services</span></div>

				<div className={styles.estimation}>Estimated wait time is <br /><b>~20</b> seconds</div>
				<br />
				<div className={styles.optin} onClick={onClick}>CHAT</div>
				<br />
				<div>We can help sponsor your requirements.</div>
			</div>
	</div>;
};

module.exports = observer(CTA);