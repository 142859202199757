import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'setimmediate';

function renderApp() {
	ReactDOM.render(
		<App />,
		document.querySelector('#root')
	);
}

// module.hot.accept(renderApp);

window['_fs_debug'] = false;
window['_fs_host'] = 'fullstory.com';
window['_fs_org'] = 'KKGK0';
window['_fs_namespace'] = 'FS';
(function(m,n,e,t,l,o,g,y){
    if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
    g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
    o=n.createElement(t);o.async=1;o.src='https://'+window['_fs_host']+'/s/fs.js';
    y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
    g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
    g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
    g.consent=function(a){g("consent",!arguments.length||a)};
    g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
    g.clearUserCookie=function(){};
})(window,document,window['_fs_namespace'],'script','user');

(function(w,d,t,r,u){
  var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"56012902"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
})(window,document,"script","//bat.bing.com/bat.js","uetq");

(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"56012902"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
window.uetq = window.uetq || [];

window.gtag_report_conversion = url => {
    var callback = function () {
        if (typeof(url) != 'undefined') {
            window.location = url;
        }
    };

    window.gtag('event', 'conversion', {
        'send_to': 'AW-1061317385/zQ1ICIDw95kBEInWifoD',
        'event_callback': callback
    });

    return false;
}

window.gtag_report_first_message = url => {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };

  window.gtag('event', 'conversion', {
      'send_to': 'AW-1061317385/4x_bCP2XpZ0BEInWifoD',
      'event_callback': callback
  });

  return false;
}

renderApp();