import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Store from './models/Store';
import { isMobile } from 'react-device-detect';

import styles from './Chat.css';

const Chat = () => {
	const { name, conversation, isTyping, isConversationOver, sendMessage, hasEmail, isOptedIn, optOut, promptForName, isShowingNamePrompt } = Store;

	const [sizeContainerHeight, setSizeContainerHeight] = useState(50);
	const [currentMessage, setCurrentMessage] = useState('');

	const messageInput = useRef(null),
		messagesContainer = useRef(null),
		sizeContainerRef = useRef(null);

	function onSendClick (event) {
		if (isShowingNamePrompt) {
			return;
		}

		if (messageInput.current.value && !isTyping) {
			if (!name) {
				promptForName(messageInput.current.value);
			} else {
				sendMessage(messageInput.current.value);
			}

			messageInput.current.value = '';
		}

		messageInput.current.focus();
		if (event) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function onKeyPress (event) {
		if (event.key === 'Enter' && !event.shiftKey) {
			onSendClick();
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function onChange () {
		setCurrentMessage(messageInput.current.value);
	}

	function onExitChat () {
		optOut();
	}

	function onMessageContainerClick(event) {
		messageInput.current.focus();
		event.preventDefault();
	}

	useEffect(() => {
		messagesContainer.current.scrollTop = messagesContainer.current.scrollHeight - messagesContainer.current.clientHeight;

		let newSize = sizeContainerRef.current.clientHeight + 40;

		if (document.body.clientHeight * 0.8 < newSize) {
			newSize = null;
		}

		setSizeContainerHeight(newSize);
	}, [conversation, isTyping, isConversationOver]);

	useEffect(() => {
		if (name && !isConversationOver && !isShowingNamePrompt) {
			messageInput.current.focus();
		}
	}, [name]);

	return <div>
		<div className={styles.messagesClickContainer} onClick={onMessageContainerClick} style={{bottom: isConversationOver ? 0 : null}}>
			<div className={styles.messages} ref={messagesContainer} style={isMobile && sizeContainerHeight ? {
					top: 'unset',
					height: sizeContainerHeight
				} : {bottom: isConversationOver ? 0 : null}}>
				<div ref={sizeContainerRef}>
					<div className={styles.notice}>
						You have been put in contact with <b>Pang</b>, she will ask you a few general questions so we can better assit you.
						<br />
						<br />
						{isOptedIn ? <div onClick={onExitChat}>To exit chat click <b>HERE</b></div> : null }
					</div>
					{
						conversation.map((message, key) => {
							let isSamePerson = conversation[key - 1] && conversation[ key - 1].from == conversation[ key ].from;

							return <div key={key} className={styles.message} style={{marginTop: isSamePerson && -7}}>
								{!isSamePerson && message.from === 'receiver' && <div className={styles.messageFromReceiver}>pang<span>{message.time}</span></div>}
								{!isSamePerson && message.from === 'sender' && <div className={styles.messageFromSender}><span>{message.time}</span>{name.split(' ')[0].toLowerCase()}</div>}

								<div className={
									message.from === 'sender' ?
									styles.messageSender :
									styles.messageReceiver
								}>
									{
										message.message.split('\n')
											.map(
												(line, key) => <div key={key}>{line.split(/\s/g).map((word, index) => {
													if (word.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/)) {
														return <React.Fragment key={index}><a href={word} target="_blank" rel="noopener noreferrer">{word}</a> </React.Fragment>;
													} else if (!word) {
														return <React.Fragment key={index}><br /></React.Fragment>;
													} else {
														return <React.Fragment key={index}>{word} </React.Fragment>;
													}
												})}</div>
											)}
								</div>
							</div>
						})
					}
					{isTyping && !isConversationOver && <div className={styles.isTyping}>
						<div className={styles.ticontainer}>
							<div className={styles.tiblock}>
								<div className={styles.tidot}></div>
								<div className={styles.tidot}></div>
								<div className={styles.tidot}></div>
							</div>
						</div>
					</div>}

					{isConversationOver && hasEmail && <div className={styles.conversationOver}>
						<p><b>Pang</b> has handed you off to <b>Grace</b> to continue your inqiry. Please check your email ASAP.</p>
					</div>}
					{isConversationOver && !hasEmail && <div className={styles.conversationOver}>
						<p><b>Pang</b> has left the chat.</p>
					</div>}
				</div>
			</div>
		</div>
		{!isConversationOver && <div className={styles.messageBox}>
			<textarea ref={messageInput} onKeyPress={onKeyPress} onChange={onChange} placeholder="Aa"></textarea>
			<button onClick={onSendClick} style={isTyping || !currentMessage ? {opacity: 0.1} : null}>SEND</button>
		</div>}
	</div>;
};

module.exports = observer(Chat);