import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Store from './models/Store';
import styles from './NamePrompt.css';

const NamePrompt = () => {
	const { setName } = Store;

	const nameInput = useRef(null);

	let [nameValue, setNameValue] = useState('');

	const isNameValid = !!nameValue.match(/\S+\s+\S+/i);

	function onNextClick () {
		if (nameInput.current.value && isNameValid) {
			setName(nameInput.current.value);
		}
	}

	function onKeyPress (event) {
		if (event.key === 'Enter' && !event.shiftKey) {
			onNextClick();
			event.preventDefault();
			event.stopPropagation();
		}
	}

	function onChange () {
		setNameValue(nameValue = nameInput.current.value);
	}

	useEffect(() => {
		if (!name) {
			nameInput.current.focus();
		}
	}, [name]);

	return <div className={styles.container}>
		<div className={styles.box}>
			<div>
				The visa specialist has requested your <b>Full Name</b>.
				<br />
				<br />
				Please provide your <b>Full Name</b> to continue.
			</div>
			<div className={styles.form}>
				<input ref={nameInput} placeholder="Full Name" onKeyPress={onKeyPress} onChange={onChange} autoComplete="off" />
				<button onClick={onNextClick} style={!isNameValid ? {opacity: 0.1} : null}>SEND</button>
			</div>
		</div>
	</div>;
};

module.exports = observer(NamePrompt);