import React from 'react';
import { observer } from 'mobx-react';

import styles from './LineInfo.css';

const LineInfo = () => {
	return <div className={styles.container}>
			<div className={styles.content}>
				<div>It seems like you are having trouble communicating over Web Chat please try adding us on LINE, or calling us.</div>
				<br />
				<div>
					<a href="http://bit.ly/thaivisacentre"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/en.png" alt="Add Friend" height="36" border="0" /></a>
				</div>
				<br />
				<br />
				<div>
					<a href="tel:+66-2-508-8428">+66 2-508-8428</a>
				</div>
			</div>
	</div>;
};

module.exports = observer(LineInfo);