import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Promise from 'bluebird';

import Store from './models/Store';

import styles from './ChatQueue.css';

const ChatQueue = () => {
	const { assignAgent } = Store;
	let [position, setPosition] = useState(7 + Math.floor(Math.random() * 15));

	useEffect(() => {

		let interval = setTimeout(async () => {
			while (position) {

				if (position) {
					position = position - Math.ceil(Math.random() * 3);
					if (position <= 0) {
						assignAgent();
						break;
					}

					setPosition(position);
				}

				await Promise.delay(200 + (2000 * Math.random()));
			}
		}, 0);

		return () => {
			setTimeout(interval);
		};
	}, []);

	return <div className={styles.container}>
		<div className={styles.box}>
				<div className={styles.heading}>Waiting for a visa specialist</div>

				<div>Your current queue position</div>

				<div className={styles.position}>{position}</div>

				<div>estimated wait time is less than<br /><br /><b>{Math.ceil(position * 1.7)} seconds</b></div>
		</div>
	</div>;
};

module.exports = observer(ChatQueue);