import React from 'react';
import { observer } from 'mobx-react';
import Store from './models/Store';
import styles from './Landing.css';

const Landing = () => {
	const { optIn } = Store;

    function onConversion () {
        window.fbq('track', 'CompleteRegistration', {
            content_name: '1 Year Retirement Visa',
            status: true,
            currency: 'USD',
            value: 1
        });

        window.uetq.push('event', 'lead', {'event_category': 'contact', 'event_label': 'conversion', 'event_value': '1'});
    }
    
    function onClick () {
		optIn();
    }
    
	return <div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.header}>Thai Visa Centre</div>
                <div className={styles.description}>
                    We provide long term visa solutions for expats, with guaranteed options. If you are interested in such services please have a quick chat with of our visa specialists.
                    <br /><br />
                    We will quickly, provide a process, and cost for your situation.
                    <br /><br />
                    We have staff members available 24/7 which can answer your general questions on <b>LINE</b> and <b>WEB CHAT</b>.
                </div>
                <div className={styles.buttons}>
                    <div className={styles.live}>
                        <a className={styles.line} href="https://bit.ly/thaivisacentre" target="_blank" onClick={onConversion}>LINE @</a>
                        <a className={styles.webchat} onClick={onClick}>Web Chat</a>
                    </div>
                    <span>or</span>
                    <div className={styles.standard}>
                        <a className={styles.wechat} href="https://bit.ly/thaivisacentre_wechat" target="_blank" onClick={onConversion}>WeChat</a>
                        <a className={styles.phone} href="tel:+66-2-508-8428" onClick={onConversion}>+66 2-508-8428</a>
                    </div>
                </div>
			</div>
	</div>;
};

module.exports = observer(Landing);